import {Box} from '@indoqa/style-system'
import {authenticator, Permission, useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import history from '../../app/history'
import {Reload, SelectSuggestion} from '../../commons-shared/search-input/SearchInput.types'
import {Button} from '../../commons/components/button/Button'
import {FooterPanel} from '../../commons/components/footer/FooterPanel'
import {DigitalHelmet} from '../../commons/components/helmet/DigitalHelmet'
import {InfoContainer} from '../../commons/components/info/InfoContainer'
import {NotificationPanel} from '../../commons/components/notification/NotificationPanel'
import {SearchAreaPanel} from '../../commons/components/search-area/SearchAreaPanel'
import {Pool, sendSelectSignal} from '../../commons/idqsa/idqsa'
import {ContentLayout} from '../../commons/layouts/content-layout/ContentLayout'
import {STRUCTURE_IDS} from '../../commons/store/structure.service'
import {digitalLoadMappedContent} from '../../digital/store/digital.actions'
import {selectDigitalMappedContentNodes} from '../../digital/store/digital.selectors'
import {useError} from '../../error/hooks/useError'
import {ErrorPage} from '../../error/pages/ErrorPage'
import {lawQueryParams} from '../../law/store/law.paths'
import {KvHeaderPanel} from '../components/header/KvHeaderPanel'
import {KvChangesOverviewListPanel} from '../components/overview/KvChangesOverviewListPanel'
import {KvNewsPanel} from '../components/overview/KvNewsPanel'
import kvSearchAreaBackgroundImage from '../components/overview/KvOverviewSearchBg.jpg'
import {KvStage} from '../components/overview/KvStage'
import {KvExtendedSearch} from '../components/search/KvExtendedSearch'
import {KvSearchInput} from '../components/search/KvSearchInput'
import {KvSearchResultPanel} from '../components/search/KvSearchResultPanel'
import {KvFilters, kvSearch, searchAutoComplete} from '../store/kv.actions'
import {kvProductName} from '../store/kv.constants'
import {kvPathKvDocSetId, kvPathOverview, kvQueryParams} from '../store/kv.paths'
import {
  selectKvSearchAutoCompleteItems,
  selectKvSearchLoading,
  selectKvSearchResult,
  selectKvSearchUserQuery,
} from '../store/kv.selectors'
import {KvAutoCompleteItem} from '../store/kv.types'

export type PerformFiltering = (nextFilters: KvFilters) => void

const TOP_BOX_LEFT = 'homepage-top-box-left'
const BOTTOM_BOX_LEFT = 'homepage-bottom-box-left'
const BOTTOM_BOX_CENTER = 'homepage-bottom-box-center'

export const KvOverviewPage: React.FC = () => {
  const q = useQueryParam(lawQueryParams.userQuery) ?? undefined
  const kvFilters: KvFilters = {
    ang_arb: useQueryParam(kvQueryParams.filterAngArb),
    contentModified: useQueryParam(kvQueryParams.filterContentModified),
    province: useQueryParam(kvQueryParams.filterProvince),
    union: useQueryParam(kvQueryParams.filterUnion),
    validOn: useQueryParam(kvQueryParams.validityDate),
    sector: useQueryParam(kvQueryParams.sector),
  }
  const permissionKVS = authenticator.hasPermission(Permission.KVSYSTEM)

  // const filtersSet = isKvFiltersSet(kvFilters)
  const dispatch = useDispatch()

  React.useLayoutEffect(() => {
    dispatch(digitalLoadMappedContent(STRUCTURE_IDS.kvsystem, [BOTTOM_BOX_CENTER, BOTTOM_BOX_LEFT, TOP_BOX_LEFT]))
  }, [dispatch])

  // only once to perform the initial search request
  React.useEffect(() => {
    dispatch(kvSearch(q, kvFilters))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    q,
    dispatch,
    kvFilters.contentModified,
    kvFilters.union,
    kvFilters.ang_arb,
    kvFilters.province,
    kvFilters.validOn,
    kvFilters.sector,
  ])

  // call events
  const performFiltering: PerformFiltering = (nextFilters) => {
    history.push(kvPathOverview(q, nextFilters))
  }
  const selectSuggestion: SelectSuggestion<KvAutoCompleteItem> = (item) => {
    sendSelectSignal(Pool.KVS, item.docSetId, '-')
    history.push(kvPathKvDocSetId(item.docSetId))
  }
  const executeSearch = (query: string) => {
    history.push(kvPathOverview(query, kvFilters))
  }
  const clearSearch = () => {
    history.push(kvPathOverview())
  }
  const reloadSearch: Reload = (query: string) => {
    dispatch(searchAutoComplete(query))
  }

  // selectors
  const searchResult = useSelector(selectKvSearchResult)
  const loading = useSelector(selectKvSearchLoading)
  const userQuery = useSelector(selectKvSearchUserQuery)
  const items = useSelector(selectKvSearchAutoCompleteItems) || []
  const mappedContentNodes = useSelector(selectDigitalMappedContentNodes)

  const contentNodeTopBoxLeft = mappedContentNodes[STRUCTURE_IDS.kvsystem]?.[TOP_BOX_LEFT]

  // error handling
  const hasError = useError()
  if (hasError) {
    return <ErrorPage />
  }

  const extendedSearch = <KvExtendedSearch filters={kvFilters} performFiltering={performFiltering} />
  const header = <KvHeaderPanel noSearch />
  const footer = <FooterPanel />

  const openNotificationLink = () => {
    window.open(
      window.location.origin + '/anmeldung-kv-online-schulung-20250311.html',
      'anmeldung-kv-online-schulung-20250311.html'
    )
  }

  return (
    <ContentLayout header={header} footer={footer} noFooterMarginTop={searchResult === null}>
      <DigitalHelmet title={kvProductName} canonical={kvPathOverview()} />

      {permissionKVS && (
        <>
          <SearchAreaPanel backgroundImage={kvSearchAreaBackgroundImage} extendedSearch={extendedSearch}>
            <KvSearchInput
              items={items}
              userQuery={userQuery || ''}
              search={executeSearch}
              reload={reloadSearch}
              selectSuggestion={selectSuggestion}
              showClear={searchResult !== null}
              clear={clearSearch}
            />
            <Box px={2} pt={1} display={['none', 'block']} style={{lineHeight: 1}} fontSize="small">
              Über das Suchfeld können Sie sowohl alle Kollektivverträge aufrufen, als auch dokumentenübergreifend den
              Inhalt durchsuchen.
            </Box>
          </SearchAreaPanel>
          {loading ? null : searchResult ? (
            <KvSearchResultPanel
              searchResult={searchResult}
              filters={kvFilters}
              performFiltering={performFiltering}
              userQuery={userQuery}
            />
          ) : (
            <>
              <Box mb={4} />
              <NotificationPanel
                validUntil={new Date(2025, 2, 11)}
                contentLeft={
                  <>
                    <b>Online-Schulung</b> für alle Benutzer:innen des KVSystems am <b>11.03.2025 um 11:00-12:00 Uhr</b>
                  </>
                }
                contentRight={
                  <>
                    <Button linkTo={openNotificationLink} text="Zur Anmeldung" />
                  </>
                }
                onClick={openNotificationLink}
              />
              <KvNewsPanel />
              <KvChangesOverviewListPanel />
              <InfoContainer />
            </>
          )}
        </>
      )}

      {!permissionKVS && (
        <>
          <KvStage contentNodeLeft={contentNodeTopBoxLeft} />
          <KvNewsPanel />
          <KvChangesOverviewListPanel />
          <InfoContainer />
        </>
      )}
    </ContentLayout>
  )
}
